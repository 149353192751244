body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.bg-dark body {
  background-color: #000 !important;
}

.bg-dark .nav-link {
  color: #fff !important;
}

.nav-link.active {
  color: #343a40 !important;
}

.bg-dark button.btn-link {
  color: #fff !important;
}

.bg-dark div.modal-header .btn-close {
  color: #fff !important;
}

.bg-dark .signatureCanvas {
  background-color: rgb(223, 223, 223);
  border: 1px solid rgb(0, 255, 68);
  width: 100%;
  height: 200px;
}

.wrapper {
  max-width: 750px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.bp5-dialog-header .bp5-icon-edit {
  color: green !important;
}

.search-results {
  position: relative;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  max-height: 150px;
  border-radius: 5px;
  overflow-y: auto;
  margin-top: 5px;
  width: 100%; /* Take up the full width */
  z-index: 1; /* Ensure it appears above other elements */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  left: 0; /* Align with the left edge of the input field */
}

.search-result {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  left: 0;
}

.search-result:hover {
  background-color: #a7edf9;
}

.signatureCanvas {
  background-color: white;
  border: 1px solid blue;
  width: 100%;
  height: 200px;
}

.modal-fullscreen {
  width: 100vw;
  max-width: 100%;
  margin: 0;
}

.modal-fullscreen .modal-dialog {
  margin: 0;
}

.order-card-styling {
  background: linear-gradient(
    to right,
    rgba(239, 236, 236, 0.3),
    rgba(99, 111, 236, 0.1)
  );
  padding: 10px 0px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.dashboard-col {
  background: linear-gradient(
    to right,
    rgba(239, 236, 236, 0.3),
    rgba(99, 111, 236, 0.1)
  );
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 15px;
}

.dashboard-col > * {
  flex-grow: 1; /* Allow columns to grow and fill the available space */
  flex-basis: 0; /* Set initial size to 0 to allow them to grow */
}

.list {
  background: linear-gradient(
    to right,
    rgba(239, 236, 236, 0.3),
    rgba(99, 111, 236, 0.1)
  );
}

.navbar-content {
  display: none;
  flex-direction: column;
}

.navbar-content.open {
  display: flex;
}

@media (max-width: 768px) {
  .navbar-content {
    display: flex;
    flex-direction: column; /* Change to vertical layout on smaller devices */
    position: absolute; /* Position absolutely for full-screen menu */
    top: 56px; /* Adjust top position based on your Navbar height */
    left: 0;
    right: 0;
    background-color: #343a40;
  }

  .navbar-content > a {
    padding: 10px;
    color: white;
  }
}

/* Hide the menu icon on screens wider than 768px */
@media (min-width: 769px) {
  .bp5-minimal.menu-button {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Media Queries */

@media print {
  .hide-on-print {
    display: none;
  }
}
